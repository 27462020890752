import { AnalyticsDttrcService } from './services/guard/analytics-dttrc/analytics-dttrc.service';
import { AnalyticsIrcoService } from './services/guard/analytics-irco/analytics-irco.service';
import { ChannelService } from './services/guard/channel/channel.service';
import { AnalyticsTcService } from './services/guard/analytics-tc/analytics-tc.service';
import { TaskClassification } from './Models/task-classification';
import { AnalyticsPcrService } from './services/guard/analytics-pcr/analytics-pcr.service';
import { AnalyticsOvbuService } from './services/guard/analytics-ovb/analytics-ovbu.service';
import { AnalyticsPovService } from './services/guard/analytics-pov/analytics-pov.service';
import { AnalyticsPbuService } from './services/guard/analytics-pbu/analytics-pbu.service';
import { BrowserComponent } from './components/browser/browser.component';
import { DisplayPdfComponent } from './components/display-pdf/display-pdf.component';
// tslint:disable-next-line: max-line-length
import { DashboardProcessComplianceOverviewComponent } from './components/dashboard-process-compliance-overview/dashboard-process-compliance-overview.component';
import { DashboardPthService } from './services/guard/dashboard-pth/dashboard-pth.service';
import { DashboardPtdService } from './services/guard/dashboard-ptd/dashboard-ptd.service';
import { DashboardUvgService } from './services/guard/dashboard-uvg/dashboard-uvg.service';
import { AnalyticsCtdService } from './services/guard/analytics-ctd/analytics-ctd.service';
import { AnalyticsUabdService } from './services/guard/analytics-uabd/analytics-uabd.service';
import { AnalyticsCbwService } from './services/guard/analytics-cbw/analytics-cbw.service';
import { AnalyticsAtosService } from './services/guard/analytics-atos/analytics-atos.service';
import { AnalyticsCtcService } from './services/guard/analytics-ctc/analytics-ctc.service';
import { AnalyticsCtbuService } from './services/guard/analytics-ctbu/analytics-ctbu.service';
import { AnalyticsTtcatbuService } from './services/guard/analytics-ttcatbu/analytics-ttcatbu.service';
import { AnalyticsTtcvService } from './services/guard/analytics-ttcv/analytics-ttcv.service';
import { AnalyticsTtcatbwService } from './services/guard/analytics-ttcatbw/analytics-ttcatbw.service';
import { AnalyticsIqbsService } from './services/guard/analytics-iqbs/analytics-iqbs.service';
import { AnalyticsOiqService } from './services/guard/analytics-oiq/analytics-oiq.service';
import { MonitorTypeService } from './services/guard/monitor-type/monitor-type.service';
import { ReminderTypeService } from './services/guard/reminder-type/reminder-type.service';
import { CreateReminderService } from './services/guard/create-reminder/create-reminder.service';
import { ReminderService } from './services/guard/reminder/reminder.service';
import { MonitorService } from './services/guard/monitor/monitor.service';
import { CreateDeviceService } from './services/guard/create-device/create-device.service';
import { DeviceService } from './services/guard/device/device.service';
import { PanelTypeService } from './services/guard/panel-type/panel-type.service';
import { PanelService } from './services/guard/panel/panel.service';
import { InfoTypeService } from './services/guard/info-type/info-type.service';
import { TaskTypeService } from './services/guard/task-type/task-type.service';
import { CreateTaskService } from './services/guard/create-task/create-task.service';
import { TasksService } from './services/guard/tasks/tasks.service';
import { AdminService } from './services/guard/admin/admin.service';
import { InfoService } from './services/guard/info/info.service';
import { OverviewService } from './services/guard/overview/overview.service';
// tslint:disable-next-line: max-line-length
import { DashboardPerformanceTenHoursComponent } from './components/dashboard-performance-ten-hours/dashboard-performance-ten-hours.component';
import { EditReminderTypeComponent } from './components/edit-reminder-type/edit-reminder-type.component';
import { DashboardPerformanceComponent } from './components/dashboard-performance/dashboard-performance.component';
import { DashboardGeneralComponent } from './components/dashboard-general/dashboard-general.component';
import { ReminderDetailComponent } from './components/reminder-detail/reminder-detail.component';
import { CreateReminderComponent } from './components/create-reminder/create-reminder.component';
import { ReminderTypesComponent } from './components/reminder-types/reminder-types.component';
import { CreateReminderTypeComponent } from './components/create-reminder-type/create-reminder-type.component';
import { RemindersComponent } from './components/reminders/reminders.component';
import { MonitorsComponent } from './components/monitors/monitors.component';
import { EditMonitorItemComponent } from './components/edit-monitor-item/edit-monitor-item.component';
import { CreateMonitorItemComponent } from './components/create-monitor-item/create-monitor-item.component';
import { MonitorItemsComponent } from './components/monitor-items/monitor-items.component';
import { EditMonitorConfigurationComponent } from './components/edit-monitor-configuration/edit-monitor-configuration.component';
import { CreateMonitorConfigurationComponent } from './components/create-monitor-configuration/create-monitor-configuration.component';
import { MonitorConfigurationsComponent } from './components/monitor-configurations/monitor-configurations.component';
import { DeviceDetailComponent } from './components/device-detail/device-detail.component';
import { EditDeviceComponent } from './components/edit-device/edit-device.component';
import { CreateDeviceComponent } from './components/create-device/create-device.component';
import { DevicesComponent } from './components/devices/devices.component';
import { ClosedTaskDistributionComponent } from './components/closed-task-distribution/closed-task-distribution.component';
import { UserActivitiesByDayComponent } from './components/user-activities-by-day/user-activities-by-day.component';
import { CreatedByWeekComponent } from './components/created-by-week/created-by-week.component';
import { AverageTimeOnStatusComponent } from './components/average-time-on-status/average-time-on-status.component';
import { CycleTimeClosedComponent } from './components/cycle-time-closed/cycle-time-closed.component';
import { ClosedTasksByUserComponent } from './components/closed-tasks-by-user/closed-tasks-by-user.component';
import { TimeToClosureTrendByUserComponent } from './components/time-to-closure-trend-by-user/time-to-closure-trend-by-user.component';
// tslint:disable-next-line: max-line-length
import { TimeToClosureAverageTrendByWeekComponent } from './components/time-to-closure-average-trend-by-week/time-to-closure-average-trend-by-week.component';
import { TimeToClosureValuesComponent } from './components/time-to-closure-values/time-to-closure-values.component';
import { InQueueByStatusComponent } from './components/in-queue-by-status/in-queue-by-status.component';
import { OverviewInQueueComponent } from './components/overview-in-queue/overview-in-queue.component';
import { PanelItemDetailComponent } from './components/panel-item-detail/panel-item-detail.component';
import { EditPanelItemComponent } from './components/edit-panel-item/edit-panel-item.component';
import { CreatePanelItemComponent } from './components/create-panel-item/create-panel-item.component';
import { PanelItemsComponent } from './components/panel-items/panel-items.component';
import { UnauthorizedUserComponent } from './components/unauthorized-user/unauthorized-user.component';
import { OverviewComponent } from './components/overview/overview.component';
import { EditPanelConfigurationComponent } from './components/edit-panel-configuration/edit-panel-configuration.component';
import { CreatePanelConfigurationComponent } from './components/create-panel-configuration/create-panel-configuration.component';
import { PanelsConfigurationsComponent } from './components/panels-configurations/panels-configurations.component';
import { PanelsComponent } from './components/panels/panels.component';
import { InformationConfigurationsComponent } from './components/information-configurations/information-configurations.component';
import { EditTaskConfigurationComponent } from './components/edit-task-configuration/edit-task-configuration.component';
import { CreateTaskConfigurationComponent } from './components/create-task-configuration/create-task-configuration.component';
import { TaskConfigurationsComponent } from './components/task-configurations/task-configurations.component';
import { TaskDetailComponent } from './components/task-detail/task-detail.component';
import { ConfigurationDetailComponent } from './components/configuration-detail/configuration-detail.component';
import { CreateTaskComponent } from './components/create-task/create-task.component';
import { TasksComponent } from './components/tasks/tasks.component';
import { ConfigurationService } from './services/configuration/configuration.service';
import { CreateConfigurationComponent } from './components/create-configuration/create-configuration.component';
import { HomeComponent } from './components/Home/Home.component';
import { ConfigurationsComponent } from './components/Configurations/Configurations.component';
import { NotificationComponent } from './components/notification/notification.component';
import { CreateNotificationComponent } from './components/create-notification/create-notification.component';
import { NotificationsServiceService } from './services/notifications-service/notifications-service.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, enableProdMode } from '@angular/core';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { Router, RouterModule } from '@angular/router';
import { NotificationDetailComponent } from './components/notification-detail/notification-detail.component';

import {
   MatAutocompleteModule,
   MatButtonModule,
   MatButtonToggleModule,
   MatCardModule,
   MatCheckboxModule,
   MatChipsModule,
   MatCommonModule,
   MatDatepickerModule,
   MatDialogModule,
   MatDividerModule,
   MatExpansionModule,
   MatFormFieldModule,
   MatGridListModule,
   MatIconModule,
   MatInputModule,
   MatLineModule,
   MatListModule,
   MatMenuModule,
   MatNativeDateModule,
   MatOptionModule,
   MatPaginatorModule,
   MatProgressBarModule,
   MatProgressSpinnerModule,
   MatPseudoCheckboxModule,
   MatRadioModule,
   MatRippleModule,
   MatSelectModule,
   MatSidenavModule,
   MatSliderModule,
   MatSlideToggleModule,
   MatSnackBarModule,
   MatSortModule,
   MatStepperModule,
   MatTableModule,
   MatTabsModule,
   MatToolbarModule,
   MatTooltipModule
 } from '@angular/material';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import { AccordionModule } from 'primeng/accordion';     // accordion and accordion tab
import { MenuItem, MessageService, ConfirmationService } from 'primeng/api';                  // api

import { ToolbarModule } from 'primeng/toolbar';
import { InputTextModule } from 'primeng/inputtext';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { MenubarModule } from 'primeng/menubar';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TabViewModule } from 'primeng/tabview';
import { PanelModule } from 'primeng/panel';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { ConfirmDialog, ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { PickListModule } from 'primeng/picklist';
import { CalendarModule } from 'primeng/calendar';
import { ColorPickerModule } from 'primeng/colorpicker';
import { MultiSelectModule } from 'primeng/multiselect';
import { CardModule } from 'primeng/card';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ChartModule } from 'primeng/chart';
import { ToastModule } from 'primeng/toast';
import { InputNumberModule } from 'primeng/inputnumber';
import { ChipsModule } from 'primeng/chips';
import { MenuModule } from 'primeng/menu';
import { MegaMenuModule } from 'primeng/megamenu';

import { CookieService } from 'ngx-cookie-service';
// tslint:disable-next-line: max-line-length
import { CreateInformationConfigurationComponent } from './components/create-information-configuration/create-information-configuration.component';
// tslint:disable-next-line: max-line-length
import { EditInformationConfigurationComponent } from './components/edit-information-configuration/edit-information-configuration.component';
import { DashboardPcoService } from './services/guard/dashboard-pco/dashboard-pco.service';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ProgressBarModule } from 'primeng/progressbar';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { PanelMenuModule } from 'primeng/panelmenu';
import { SlideMenuModule } from 'primeng/slidemenu';
import { OverlayPanelModule } from 'primeng/overlaypanel';

import { OAuthModule } from 'angular-oauth2-oidc';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpConfigInterceptor } from './Interceptors/httpconfig.interceptor';

enableProdMode();

export function HttpLoaderFactory(httpClient: HttpClient) {
   return new TranslateHttpLoader(httpClient);
 }

import { KeycloakService, KeycloakAngularModule } from 'keycloak-angular';
import { environment } from 'src/environments/environment';

// Detect browser
// https://www.npmjs.com/package/detect-browser
// const { detect } = require('detect-browser');
import { detect } from 'detect-browser';
import { OverviewByUserComponent } from './components/overview-by-user/overview-by-user.component';
import { PerformanceOverviewComponent } from './components/performance-overview/performance-overview.component';
import { ProcessComplianceRankingComponent } from './components/process-compliance-ranking/process-compliance-ranking.component';
import { ListboxModule } from 'primeng/listbox';
import { AnalyticsTaskClassificationComponent } from './components/analytics-task-classification/analytics-task-classification.component';
import { ChannelComponent } from './components/channel/channel.component';
import { DragDropModule } from 'primeng/dragdrop';
import { ChannelAdmComponent } from './components/channel-adm/channel-adm.component';
import { AnalyticsInfoReadConfComponent } from './components/analytics-info-read-conf/analytics-info-read-conf.component';
// tslint:disable-next-line: max-line-length
import { AnalyticsDistTimeToReadConfComponent } from './components/analytics-dist-time-to-read-conf/analytics-dist-time-to-read-conf.component';
import { OrderComponent } from './components/order/order.component';
import { OrderService } from './services/guard/order/order.service';
import { LocationsComponent } from './components/locations/locations.component';
import { OrderCategoryComponent } from './components/order-category/order-category.component';
import { ItemDefinitionComponent } from './components/item-definition/item-definition.component';
import { ServiceWorkerModule } from '@angular/service-worker';

const keycloakService = new KeycloakService();
const browser = detect();

@NgModule({
   declarations: [
      AppComponent,
      NotificationsComponent,
      NotificationDetailComponent,
      CreateNotificationComponent,
      NotificationComponent,
      ConfigurationsComponent,
      CreateConfigurationComponent,
      HomeComponent,
      TasksComponent,
      CreateTaskComponent,
      ConfigurationDetailComponent,
      TaskDetailComponent,
      TaskConfigurationsComponent,
      CreateTaskConfigurationComponent,
      EditTaskConfigurationComponent,
      CreateInformationConfigurationComponent,
      EditInformationConfigurationComponent,
      InformationConfigurationsComponent,
      PanelsComponent,
      PanelsConfigurationsComponent,
      CreatePanelConfigurationComponent,
      EditPanelConfigurationComponent,
      OverviewComponent,
      UnauthorizedUserComponent,
      PanelItemsComponent,
      CreatePanelItemComponent,
      EditPanelItemComponent,
      PanelItemDetailComponent,
      OverviewInQueueComponent,
      InQueueByStatusComponent,
      TimeToClosureValuesComponent,
      TimeToClosureAverageTrendByWeekComponent,
      TimeToClosureTrendByUserComponent,
      ClosedTasksByUserComponent,
      CycleTimeClosedComponent,
      AverageTimeOnStatusComponent,
      CreatedByWeekComponent,
      UserActivitiesByDayComponent,
      ClosedTaskDistributionComponent,
      DevicesComponent,
      CreateDeviceComponent,
      EditDeviceComponent,
      DeviceDetailComponent,
      MonitorConfigurationsComponent,
      CreateMonitorConfigurationComponent,
      EditMonitorConfigurationComponent,
      MonitorItemsComponent,
      CreateMonitorItemComponent,
      EditMonitorItemComponent,
      MonitorsComponent,
      RemindersComponent,
      CreateReminderComponent,
      ReminderTypesComponent,
      CreateReminderTypeComponent,
      ReminderDetailComponent,
      DashboardGeneralComponent,
      DashboardPerformanceComponent,
      EditReminderTypeComponent,
      DashboardPerformanceTenHoursComponent,
      DashboardProcessComplianceOverviewComponent,
      DisplayPdfComponent,
      BrowserComponent,
      OverviewByUserComponent,
      PerformanceOverviewComponent,
      ProcessComplianceRankingComponent,
      AnalyticsTaskClassificationComponent,
      ChannelComponent,
      ChannelAdmComponent,
      AnalyticsInfoReadConfComponent,
      AnalyticsDistTimeToReadConfComponent,
      OrderComponent,
      LocationsComponent,
      OrderCategoryComponent,
      ItemDefinitionComponent
   ],
   imports: [
      BrowserModule,
      BrowserAnimationsModule,
      AppRoutingModule,
      PdfViewerModule,
      MatMenuModule,
      MatSidenavModule,
      MatListModule,
      HttpClientModule,
      MatCardModule,
      MatAutocompleteModule,
      MatButtonModule,
      MatButtonToggleModule,
      MatCardModule,
      MatCheckboxModule,
      MatChipsModule,
      MatCommonModule,
      MatDatepickerModule,
      MatDialogModule,
      MatDividerModule,
      MatExpansionModule,
      MatFormFieldModule,
      MatGridListModule,
      MatIconModule,
      MatInputModule,
      MatLineModule,
      MatListModule,
      MatMenuModule,
      MatNativeDateModule,
      MatOptionModule,
      MatPaginatorModule,
      MatProgressBarModule,
      MatProgressSpinnerModule,
      MatPseudoCheckboxModule,
      MatRadioModule,
      MatRippleModule,
      MatSelectModule,
      MatSidenavModule,
      MatSlideToggleModule,
      MatSliderModule,
      MatSnackBarModule,
      MatSortModule,
      MatStepperModule,
      MatTableModule,
      MatTabsModule,
      MatToolbarModule,
      MatTooltipModule,
      FormsModule,
      ReactiveFormsModule,
      ColorPickerModule,

      FormsModule,
      AccordionModule,
      PanelModule,
      ButtonModule,
      RadioButtonModule,
      ToolbarModule,
      SplitButtonModule,
      TableModule,
      DropdownModule,
      InputTextModule,
      MessagesModule,
      MessageModule,
      ConfirmDialogModule,
      MenubarModule,
      InputTextModule,
      ProgressSpinnerModule,
      PickListModule,
      CalendarModule,
      MultiSelectModule,
      DialogModule,
      CardModule,
      ScrollPanelModule,
      ChartModule,
      ToastModule,
      InputNumberModule,
      ChipsModule,
      MenuModule,
      ProgressBarModule,
      TieredMenuModule,
      PanelMenuModule,
      SlideMenuModule,
      CheckboxModule,
      ListboxModule,
      TabViewModule,
      DragDropModule,
      OverlayPanelModule,
      MegaMenuModule,

      OAuthModule.forRoot(),

      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
        }
      }),
      RouterModule.forRoot([
         // { path: '', redirectTo: 'overview', pathMatch: 'full' },
         { path: 'overview', component: OverviewComponent, canActivate: [ OverviewService ] },
         { path: 'notifications', component: NotificationsComponent, canActivate: [ InfoService ] },
        //  { path: 'notifications/:id', component: NotificationDetailComponent, canActivate: [ InfoService ] },
         { path: 'createnotification', component: CreateNotificationComponent, canActivate: [ InfoService ] },
         { path: 'configurationdetail/:id', component: ConfigurationDetailComponent, canActivate: [ InfoService ] },
         { path: 'tasks', component: TasksComponent, canActivate: [ TasksService ] },
         { path: 'createtask', component: CreateTaskComponent, canActivate: [ CreateTaskService ] },
         { path: 'taskdetail/:id', component: TaskDetailComponent, canActivate: [ TasksService ] },
         { path: 'taskconfigurations', component: TaskConfigurationsComponent, canActivate: [ AdminService ] },
         { path: 'createtaskconfiguration', component: CreateTaskConfigurationComponent, canActivate: [ TaskTypeService ] },
         { path: 'edittaskconfiguration', component: EditTaskConfigurationComponent, canActivate: [ TaskTypeService ] },
         { path: 'informationconfigurations', component: InformationConfigurationsComponent, canActivate: [ AdminService ] },
         { path: 'createinformationconfiguration', component: CreateInformationConfigurationComponent, canActivate: [ InfoTypeService ] },
         { path: 'editinformationconfiguration', component: EditInformationConfigurationComponent, canActivate: [ InfoTypeService ] },
         { path: 'panels/:name/:id', component: PanelsComponent, canActivate: [ PanelService ] },
         { path: 'panelconfigurations', component: PanelsConfigurationsComponent, canActivate: [ AdminService ] },
         { path: 'createpanel', component: CreatePanelConfigurationComponent, canActivate: [ PanelTypeService ] },
         { path: 'editpanel', component: EditPanelConfigurationComponent, canActivate: [ PanelTypeService ] },
         { path: 'panelitems', component: PanelItemsComponent, canActivate: [ PanelTypeService ] },
         { path: 'createpanelitem', component: CreatePanelItemComponent, canActivate: [ PanelTypeService ] },
         { path: 'editpanelitem', component: EditPanelItemComponent, canActivate: [ PanelTypeService ] },
         { path: 'panelitemdetail', component: PanelItemDetailComponent, canActivate: [ PanelTypeService ] },
         { path: 'devices', component: DevicesComponent, canActivate: [ DeviceService ] },
         { path: 'create-device', component: CreateDeviceComponent, canActivate: [ CreateDeviceService ] },
         { path: 'edit-device', component: EditDeviceComponent, canActivate: [ CreateDeviceService ] },
         { path: 'monitors/:name/:id', component: MonitorsComponent, canActivate: [ MonitorService ] },
         { path: 'reminders', component: RemindersComponent, canActivate: [ ReminderService ] },
         { path: 'reminders/:id', component: ReminderDetailComponent, canActivate: [ ReminderService ] },
         { path: 'create-reminder', component: CreateReminderComponent, canActivate: [ CreateReminderService ] },
         { path: 'remindertypes', component: ReminderTypesComponent, canActivate: [ AdminService ] },
         { path: 'create-reminder-type', component: CreateReminderTypeComponent, canActivate: [ ReminderTypeService ] },
         { path: 'edit-reminder-type', component: EditReminderTypeComponent, canActivate: [ ReminderTypeService ] },
         { path: 'monitorconfigurations', component: MonitorConfigurationsComponent, canActivate: [ AdminService ] },
         { path: 'createmonitor', component: CreateMonitorConfigurationComponent, canActivate: [ MonitorTypeService ] },
         { path: 'editmonitor', component: EditMonitorConfigurationComponent, canActivate: [ MonitorTypeService ] },
         { path: 'monitoritems', component: MonitorItemsComponent, canActivate: [ MonitorTypeService ] },
         { path: 'createmonitoritem', component: CreateMonitorItemComponent, canActivate: [ MonitorTypeService ] },
         { path: 'editmonitoritem', component: EditMonitorItemComponent, canActivate: [ MonitorTypeService ] },
         { path: 'devices/:id', component: DeviceDetailComponent, canActivate: [ DeviceService ] },
         { path: 'unauthorizeduser', component: UnauthorizedUserComponent },
         { path: 'overview-in-queue', component: OverviewInQueueComponent, canActivate: [ AnalyticsOiqService ] },
         { path: 'in-queue-by-status', component: InQueueByStatusComponent, canActivate: [ AnalyticsIqbsService ] },
         { path: 'time-to-closure-values', component: TimeToClosureValuesComponent, canActivate: [ AnalyticsTtcvService ] },
         { path: 'time-to-closure-average-trend-by-week',
                  component: TimeToClosureAverageTrendByWeekComponent, canActivate: [ AnalyticsTtcatbwService ] },
         { path: 'time-to-closure-trend-by-user', component: TimeToClosureTrendByUserComponent, canActivate: [ AnalyticsTtcatbuService ] },
         { path: 'closed-tasks-by-user', component: ClosedTasksByUserComponent, canActivate: [ AnalyticsCtbuService ] },
         { path: 'cycle-time-closed', component: CycleTimeClosedComponent, canActivate: [ AnalyticsCtcService ] },
         { path: 'average-time-on-status', component: AverageTimeOnStatusComponent, canActivate: [ AnalyticsAtosService ] },
         { path: 'created-by-week', component: CreatedByWeekComponent, canActivate: [ AnalyticsCbwService ] },
         { path: 'user-activities-by-day', component: UserActivitiesByDayComponent, canActivate: [ AnalyticsUabdService ] },
         { path: 'closed-task-distribution', component: ClosedTaskDistributionComponent, canActivate: [ AnalyticsCtdService ] },
         { path: 'dashboard-user-vs-global', component: DashboardGeneralComponent, canActivate: [ DashboardUvgService ] },
         { path: 'dashboard-performance-10-days', component: DashboardPerformanceComponent, canActivate: [ DashboardPtdService ] },
         { path: 'dashboard-performance-10-hours', component: DashboardPerformanceTenHoursComponent, canActivate: [ DashboardPthService ] },
         { path: 'dashboard-process-compliance-overview', component: DashboardProcessComplianceOverviewComponent,
           canActivate: [ DashboardPcoService ] },
         { path: 'performance-overview', component: PerformanceOverviewComponent,
           canActivate: [ AnalyticsPovService ] },
         { path: 'overview-by-user', component: OverviewByUserComponent,
           canActivate: [ AnalyticsOvbuService ] },
         { path: 'process-compliance-ranking', component: ProcessComplianceRankingComponent,
           canActivate: [ AnalyticsPcrService ] },
          { path: 'task-classification', component: AnalyticsTaskClassificationComponent,
          canActivate: [ AnalyticsTcService ] },
          { path: 'info-read-confirmation-overview', component: AnalyticsInfoReadConfComponent,
          canActivate: [ AnalyticsIrcoService ] },
          { path: 'distribution-time-to-read', component: AnalyticsDistTimeToReadConfComponent,
            canActivate: [ AnalyticsDttrcService ] },
          { path: 'user-channels', component: ChannelComponent,
          canActivate: [ ChannelService ] },
          { path: 'channels', component: ChannelAdmComponent,
            canActivate: [ AdminService ] },
          { path: 'orders', component: OrderComponent,
            canActivate: [ OrderService ] },
          { path: 'locations', component: LocationsComponent,
            canActivate: [ AdminService ] },
          { path: 'order-categories', component: OrderCategoryComponent,
            canActivate: [ AdminService ] },
            { path: 'item-definitions', component: ItemDefinitionComponent,
              canActivate: [ AdminService ] },
         { path: 'display-pdf', component: DisplayPdfComponent,
           canActivate: [ DashboardPcoService ] },
         { path: 'xxxxx', component: BrowserComponent }
       ]),
      ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
   ],
   providers: [
      CookieService,
      NotificationsServiceService,
      ConfigurationService,
      MessageService,
      ConfirmationService,
      ConfirmDialog,
      {
        provide: LocationStrategy,
        useClass: HashLocationStrategy
      },
      {
        provide: KeycloakService,
        useValue: keycloakService
      },
      { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
      PanelsComponent,
      MonitorsComponent
   ],
  //  bootstrap: [
  //     AppComponent
  //  ]
   entryComponents: [
    AppComponent
  ]
})
export class AppModule {
   constructor(
      private router: Router
      ) {
      // localStorage.clear();
      localStorage.setItem('oi', '');
      localStorage.setItem('ui', '');
    }

    ngDoBootstrap(app) {
      localStorage.setItem('browser', 'true');
      keycloakService
        .init(
          {
            config: {
              url: environment.keycloakUrl + '/auth',
              realm: environment.realm,
              clientId: environment.clientid
            },
            initOptions: {
              onLoad: 'login-required',
              checkLoginIframe: false
            },
            enableBearerInterceptor: true,
            bearerPrefix: 'Bearer',
            bearerExcludedUrls: [
                '/assets',
                '/clients/public']
          })
        .then(async () => {
          // console.log('[ngDoBootstrap] bootstrap app');

          // console.log((await keycloakService.loadUserProfile()).email)

          localStorage.setItem('oi', '');
          localStorage.setItem('ui', '');
          app.bootstrap(AppComponent);
        })
        .catch(
          error => console.error('[ngDoBootstrap] init Keycloak failed', error)
        );
    }
 }
